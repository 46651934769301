<template>
  <div>
    <div class="sticky">
      <div
        class="sticky__contact"
        @click="emitClick(contactAction)"
      >
        <div class="k-button k-button-md k-button-solid">
          <CommunicationEmail />
          <span>{{ $tv('contact', 'navigation') }}</span>
        </div>
        <div class="sticky__info">
          <span
            class="sticky__info__text"
          >
            {{ $tv('contactInfo', 'navigation') }}
          </span>
        </div>
      </div>
    </div>
    <!-- Commented out until feedback needed again #37969
    <div
      class="sticky feedback"
      :class="!feedbackButtonVisible ? 'hidden' : ''"
    >
      <div
        class="sticky__feedback"
        @click="emitClick(feedbackAction)"
      >
        <div class="k-button k-button-md k-button-solid">
          <div class="svg-box">
            <svg viewBox="0 0 28 28"><path
              d="M28 14c0 5.531-6.266 10-14 10-0.766 0-1.531-0.047-2.266-0.125-2.047 1.813-4.484 3.094-7.187 3.781-0.562 0.156-1.172 0.266-1.781 0.344-0.344 0.031-0.672-0.219-0.75-0.594v-0.016c-0.078-0.391 0.187-0.625 0.422-0.906 0.984-1.109 2.109-2.047 2.844-4.656-3.219-1.828-5.281-4.656-5.281-7.828 0-5.516 6.266-10 14-10s14 4.469 14 10z"
              fill="currentColor"
            /></svg>
          </div>
          <span>{{ $tv('feedback', 'navigation') }}</span>
        </div>
        <div class="sticky__info feedback">
          <span
            class="sticky__info__text"
          >
            {{ $tv('feedbackInfo', 'navigation') }}
          </span>
        </div>
      </div>
    </div>-->
    <div
      v-if="experimentalModeVisible"
      class="sticky experimental-features"
      :class="{'no-feedback': !feedbackButtonVisible}"
    >
      <div class="sticky__experimental-features">
        <div class="k-button k-button-md k-button-solid">
          <div class="svg-box">
            <component :is="experimentalSvg" />
          </div>
          <span>{{ $tv('mode', 'navigation') }}</span>
        </div>
        <div class="sticky__info">
          <div>
            <ToggleSwitch
              :is-checked="experimentalMode"
              :label-left="$tv('expMode', 'navigation')"
              @change-value="switchExperimentalMode"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ToggleSwitch from '~/components/input-components/ToggleSwitch'
import CommunicationEmail from '~/assets/icons/communication_email.svg?component'
import Science from '~/assets/icons/science.svg?component'
import ScienceOff from '~/assets/icons/science_off.svg?component'
import {SIDEBAR_ACTIONS} from '~/assets/js/constants'
import {useConfigurationDataStore} from '@/store/configuration-data'
import {storeToRefs} from 'pinia'

export default {
  name: 'SideButtons',
  components: {CommunicationEmail, ToggleSwitch},
  setup() {
    const {experimentalMode, configurations} = storeToRefs(useConfigurationDataStore())
    return {
      experimentalMode,
      configurations
    }
  },
  data: function() {
    return {
      contactAction: SIDEBAR_ACTIONS.Contact,
      feedbackAction: SIDEBAR_ACTIONS.Feedback
    }
  },
  computed: {
    // #37969 comment in when needed
    //feedbackButtonVisible() {
    //  return false
    //},
    experimentalSvg() {
      if (this.experimentalMode) {
        return Science
      } else {
        return ScienceOff
      }
    },
    experimentalModeVisible() {
      return false
      // TODO #32699 turn back on/off when needed
      // return this.configurations?.find(c => c.key === CONFIGURATION_KEYS.SHOW_EXPERIMENTAL)?.value === 'True'
    }
  },
  methods: {
    emitClick(action) {
      this.$emit('click', action)
    },
    switchExperimentalMode(val) {
      this.experimentalMode = !!val
    }
  }
}
</script>

<style lang="scss" scoped>
.sticky {
  display: block;
  overflow: hidden;
  position: fixed;
  right: 0;
  width: 12.5rem;
  height: 4.0625rem;
  cursor: pointer;
  text-transform: uppercase;
  z-index: 1000;
  top: 21.25rem;
  transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
  transform: translate3d(8.4375rem, 0, 0);

  &.feedback {
    top: 25.5rem;
  }

  &.experimental-features {
    top: 29.75rem;
    width: 15.5rem;
    transform: translate3d(11.4375rem, 0, 0);

    &.no-feedback {
      top: 25.5rem;
    }
  }

  &__experimental-features {
    .k-button,
    .sticky__info {
      background-color: $color__secondary;
    }

    .sticky__info {
      width: 11.4375rem;
      display: flex;
      align-items: center;

      > div {
        margin-right: .5rem;
      }
    }

    .k-button span {
      position: relative;
      top: -.35rem;
    }

    svg {
      width: 2rem;
      height: 2rem;
    }
  }

  &:hover {
    transform: translateZ(0);
  }

  &__contact {
    .k-button,
    &__info {
      background-color: $color__secondary;
    }

    .icon--svg {
      font-size: 2rem;
      width: 2rem;
    }
  }

  &__feedback {
    .k-button,
    &__info {
      background-color: $color__primary;
    }
  }

  &__feedback .svg-box {
    margin-bottom: .188rem;
    height: 1.75rem;
    width: 1.75rem;
  }

  .k-button {
    width: 4.0625rem;
    min-width: 0;
    float: left;
    flex-direction: column;
    display: flex;
    text-align: center;
    font-size: map-get($base-text-button, font-size);
    letter-spacing: -.0313rem;
    text-transform: none;
    color: $color__white;

    .icon--svg {
      font-size: 2.1875rem;
    }
  }

  &__info {
    float: left;
    display: table-cell;
    width: 8.4375rem;
    height: 4.0625rem;
    text-align: center;
    vertical-align: middle;
    background-color: $color__secondary;

    &.feedback {
      background-color: $color__primary;
    }

    &__text {
      display: inline-block;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      color: $color__white;
      text-decoration: underline;
      padding-right: .625rem;
      font-weight: $base-text-font-weight-light;
    }
  }

  .hidden {
    display: none;
  }
}
</style>

<style lang="scss">
.sticky__experimental-features {
  .toggle-switch-container > span {
    color: $color__white;
    font-size: $base-text-font-size;
    padding-right: .2rem;
    font-weight: $base-text-font-weight-semi-bold;
  }
}
</style>
