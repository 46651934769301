<template>
  <div class="nav__main__searchbar">
    <div
      class="nav__main__search__input__category"
      @click="handleCategoryClick"
    >
      <div>
        {{ selectedCategory.text }}
        <NavigationExpandMore
          class="svg-navigation-expand-more"
        />
      </div>
    </div>
    <div class="nav__main__search__input search-input-field-component">
      <a
        :title="$tv('searchSearchIconTooltip', 'header.services')"
        href="javascript:void(0)"
        class="nav__main__search__input__icon"
        @click="runSearch"
      >
        <ActionSearch />
      </a>
      <WaitingIndicator
        class="nav__main__search__input__loading-indicator"
        v-if="isLoading"
      />
      <input
        class="pure-input-1 input__icon--left data-hj-allow"
        type="search"
        ref="search"
        :placeholder="$tv('searchPlaceholder', 'header.services')"
        maxlength="299"
        v-model="searchTerm"
        @keyup.enter="runSearch"
        @click="openOverlay"
        @blur="blurSearch"
        @focus="openOverlayFocus"
      >
      <UnitSwitch
        :is-inch-search="isInchSearch"
        @changeUnit="handleChange"
      />
    </div>
  </div>
</template>

<script>
import ActionSearch from '~/assets/icons/action_search.svg?component'
import WaitingIndicator from '~/components/loading-indicators/waiting-indicator.vue'
import UnitSwitch from '~/components/search/UnitSwitch'
import NavigationExpandMore from '~/assets/icons/navigation_expand_more.svg?component'
import {storeToRefs} from 'pinia'
import {useConfigurationDataStore} from '@/store/configuration-data'

export default {
  components: {
    ActionSearch,
    WaitingIndicator,
    UnitSwitch,
    NavigationExpandMore
  },
  props: {
    focus: {
      type: Boolean,
      required: false,
      default() {
        return false
      }
    },
    isLoading: {
      type: Boolean,
      default() {
        return true
      }
    },
    isInchSearch: {
      type: Boolean,
      default() {
        return false
      }
    },
    selectedCategory: {
      type: Object,
      default() {
        return {}
      }
    },
    directLink: {
      type: String,
      default() {
        return null
      }
    },
    overlayVisible: {
      type: Boolean,
      default() {
        return false
      }
    },
    searchTermResult: {
      type: Object,
      default() {
        return {
          facets: {
            primaryL2Category: []
          }
        }
      }
    }
  },
  setup() {
    const {newSearch} = storeToRefs(useConfigurationDataStore())

    return {
      newSearch
    }
  },
  data() {
    return {
      searchTerm: '',
      timeout: null,
      expanded: false
    }
  },
  methods: {
    runSearch() {
      if (this.searchTerm !== '') {
        this.$emit('search-triggered')
        this.$emit(this.searchTerm)
        this.$emit('open-overlay')
        if (this.directLink) {
          this.$router.push(this.directLink)
        } else {
          let routerOptions = {
            path: this.localePath({name: 'searchResults'}),
            query: {
              query: this.searchTerm,
              measurement: this.isInchSearch ? 'inch' : 'mm',
              ProductDesign: this.queryWithCategory()
            }}
          if (this.searchTermResult?.facets?.primaryL2Category?.length === 1) {
            routerOptions.query.PrimaryL2Category = this.searchTermResult.facets.primaryL2Category[0].value
          }
          this.$router.push(routerOptions)
        }
        this.$emit('close-overlay')
      }
    },
    queryWithCategory() {
      if (!this.selectedCategory?.searchFilter?.length) {
        return ''
      }

      let catFilter = ''
      this.selectedCategory.searchFilter.forEach(filter => {
        if (catFilter === '') {
          catFilter = `${filter}`
        } else {
          catFilter += `$${filter}`
        }
      })

      return catFilter
    },
    blurSearch() {
      this.$emit('search-blur')
    },
    focusSearch() {
      setTimeout(() => this.$refs.search.focus(), 200)
    },
    openOverlay() {
      if (this.searchTerm !== '') {
        setTimeout(() => {
          this.$emit('openOverlay', this.searchTerm)
        }, 100)
      }
    },
    openOverlayFocus() {
      if (this.searchTerm !== '') {
        setTimeout(() => {
          this.$emit('openOverlay', this.searchTerm)
        }, 100)
      }
    },
    handleChange() {
      this.$emit('changeUnit', this.searchTerm)
    },
    handleCategoryClick() {
      this.$emit('open-category-tooltip')
    }
  },
  watch: {
    focus: {
      handler(val) {
        if (val) {
          this.focusSearch()
        }
      }
    },
    searchTerm(val) {
      clearTimeout(this.timeout)

      this.timeout = setTimeout(async () => {
        await this.$emit('searchTerm', val.length ? val : '')
      }, 200)
    },
    '$route.path': {
      handler() {
        this.searchTerm = ''
      }
    }
  }
}
</script>

<style lang="scss">
.nav__main__search {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 28rem;
}

.nav__main__searchbar {
  border: .156rem solid $color__primary;
  width: 100%;
}

.pure-form .nav__main__search__input {
  position: relative;
  flex-grow: 1;

  input {
    font-size: map-get($base-text-label-medium, font-size );
    color: $color__text__search;
    padding-right: 4.75rem;

    /* clears the 'X' from Internet Explorer */
    &::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }

    &::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }

    /* clears the 'X' from Chrome */
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration { display: none; }
  }

  .nav__main__search__input__loading-indicator {
    position: absolute;
    top: .5rem;
    right: 2.95rem;
  }

  .nav__main__search__input__icon .icon--svg {
    position: absolute;
    top: calc(50% - .5rem);
    left: .5em;
    font-size: 1.1rem;
  }

  @include breakpoint-down($md) {
    input {
      width: calc(100% - 2.4rem);
      height: 2.5rem;
      border-radius: 0;
      box-shadow: none;
      border: .0625rem solid $color--input__border;
      padding-left: $default-margin-padding-2x;
      font-size: map-get($base-text-label-medium, font-size );
      color: $color__text__search;
      padding-right: 7.4rem;
      outline: none;
    }

    .nav__main__search__input__loading-indicator {
      position: absolute;
      top: .5rem;
      right: 8rem;
    }
  }
}

.nav__main__search__input__category {
  width: auto;
  height: auto;
  padding-left: .5rem;
  padding-right: .5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color__primary;
  color: $color__white;
  font-size: $base-text-font-size;
  cursor: pointer;
  padding-top: .25rem;

  .svg-navigation-expand-more {
    vertical-align: middle;
    font-size: 1.5rem;
    padding-bottom: .125rem;
  }
}

#SearchOverlayMobile .nav__main__search__input__category {
  display: none;
}
</style>
