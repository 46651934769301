/* eslint-disable eqeqeq */
import {defineStore} from 'pinia'
import {
  HydraulicSelectorState,
  HydraulicSelectorModule,
  inputs,
  Checkboxes
} from '@/types/hydraulic-selector-store.d'
import type {components} from '@/types/hydras.d'
import {newEmptyPostBody} from '@/types/helper.d'
import {useHydraulicSelectorNAService} from '~/composables/hydraulic_selector_na_service'
import {REGION_KEYS} from '~/assets/js/constants'

export const useHydraulicSelectorStore = defineStore(
  'HydraulicSelectorModule',
  (): HydraulicSelectorModule => {
    // State
    type RequestBody = components['schemas']['ApiPostProductRequest'];
    type Presets = Ref<components['schemas']['ApiApplicationPreset'][]>;
    type Metadata = Ref<components['schemas']['ApiHydraulicMetaData']>;
    const selectorState: Ref<HydraulicSelectorState> = ref(
      HydraulicSelectorState.startpage
    )
    const currentData = ref<RequestBody>({})
    const presets: Presets = ref([])
    const presetsImperial: Presets = ref([])
    const metadata: Metadata = ref({})
    const metadataImperial: Metadata = ref({})
    const basicInput: Ref<inputs['BasicInputs']> = ref({sealsNeeds: 'newCylinderDesign'})
    const checkboxes: Ref<Checkboxes> = ref({})
    const replacementStep: Ref<number> = ref(0)
    const wiperProfile: Ref<string> = ref('')
    const rodSealProfile: Ref<string> = ref('')
    const rodGuideProfile: Ref<string> = ref('')
    const bufferSealProfile: Ref<string> = ref('')
    const pistonSealProfile: Ref<string> = ref('')
    const pistonGuideProfile: Ref<string> = ref('')
    const wiperDefault: Ref<string> = ref('design_ab')
    const pistonSealDefaultB: Ref<boolean> = ref(false)
    const isErrorResponse: Ref<boolean> = ref(false)
    const unitLength = ref('mm')
    const unitTemperature = ref('celsius')
    const unitPressure = ref('bar')
    const unitSpeed = ref('m/s')
    const usStandardSizes = ref(false)

    // Getters
    const currentRegion = computed(() => useNuxtApp().$globalization.getRegion())

    const presetsAndMetadataInitialized = computed<boolean>(() => {
      return presets.value.length > 0 && Object.keys(metadata.value).length > 0
    })

    const unitSwitchingEnabled = computed<boolean>(() => {
      return useRuntimeConfig().public.HYDRAS_UNIT_SWITCHER_ENABLED === '1' && currentRegion.value === REGION_KEYS.NA
    })

    const currentPresets = computed(() => {
      const metric = presets.value?.find((p) => p.applicationPreset === basicInput.value?.applicationArea ?? 'agriculture-machinery')
      const imperial = presetsImperial.value?.find((p) => p.applicationPreset === basicInput.value?.applicationArea ?? 'agriculture-machinery')
      return {
        applicationPreset: basicInput.value?.applicationArea ?? 'agriculture-machinery',
        temperatureMax: unitTemperature.value === 'celsius' ? metric?.temperatureMax : imperial?.temperatureMax,
        temperatureMin: unitTemperature.value === 'celsius' ? metric?.temperatureMin : imperial?.temperatureMin,
        pressureMax: unitPressure.value === 'bar' ? metric?.pressureMax : imperial?.pressureMax,
        pressureConst: unitPressure.value === 'bar' ? metric?.pressureConst : imperial?.pressureConst,
        speedMax: unitSpeed.value === 'm/s' ? metric?.speedMax : imperial?.speedMax,
        cylinderType: unitSwitchingEnabled.value ? imperial?.cylinderType : metric?.cylinderType,
        dirtExposure: unitSwitchingEnabled.value ? imperial?.dirtExposure : metric?.dirtExposure,
        operatingDuration: unitSwitchingEnabled.value ? imperial?.operatingDuration : metric?.operatingDuration
      }
    })

    const dataIncompleteForStepTwo = computed<boolean>(() => {
      return (
        (basicInput.value?.rodDiameter == null &&
        basicInput.value?.outerDiameter == null) ||
        basicInput.value?.sealsNeeds == null
      )
    })

    const isInch = computed<boolean>(() => {
      return unitLength.value === 'inch'
    })

    const getPostRequestObject = computed<
      components['schemas']['ApiPostProductRequest']
    >(() => {
      const defaultWiper: components['schemas']['WiperDto'] = {
        rodDiameter: basicInput.value?.rodDiameter,
        outerDiameter: null,
        installationSpaceWidth: null,
        installationSpaceWidth1: null,
        outerDiameter1: null,
        variant: null
      }
      const defaultRodSeal: components['schemas']['RodSealDto'] = {
        rodDiameter: basicInput.value?.rodDiameter,
        outerDiameter: null,
        installationSpaceWidth: null
      }
      const defaultRodGuide: components['schemas']['RodGuideDto'] = {
        rodDiameter: basicInput.value?.rodDiameter,
        outerDiameter: null,
        installationSpaceWidth: null
      }

      const defaultPistonSeal: components['schemas']['PistonSealDto'] = {
        innerDiameter: null,
        boreDiameter: basicInput.value?.outerDiameter,
        installationSpaceWidth: null,
        innerDiameter1: null,
        installationSpaceWidth1: null,
        variant: null
      }

      const defaultPistonGuide: components['schemas']['PistonGuideDto'] = {
        innerDiameter: null,
        boreDiameter: basicInput.value?.outerDiameter,
        installationSpaceWidth: null
      }
      const defaultBufferSeal: components['schemas']['BufferSealDto'] = {
        rodDiameter: basicInput.value?.rodDiameter,
        outerDiameter: null,
        installationSpaceWidth: null
      }
      const unitsOfMeasureDefault = {
        temperature: unitTemperature.value,
        pressure: unitPressure.value,
        speed: unitSpeed.value,
        length: unitLength.value
      }

      return {
        unitsOfMeasure: unitsOfMeasureDefault,
        temperatureMax:
          currentData.value.temperatureMax == null
            ? currentPresets.value?.temperatureMax
            : currentData.value.temperatureMax,
        temperatureMin:
          currentData.value.temperatureMin == null
            ? currentPresets.value?.temperatureMin
            : currentData.value.temperatureMin,
        pressureMax:
          currentData.value.pressureMax == null
            ? currentPresets.value?.pressureMax
            : currentData.value.pressureMax,
        pressureConst:
          currentData.value.pressureConst == null
            ? currentPresets.value?.pressureConst
            : currentData.value.pressureConst,
        speedMax:
          currentData.value.speedMax == null
            ? currentPresets.value?.speedMax
            : currentData.value.speedMax,
        cylinderType:
          currentData.value.cylinderType == null
            ? currentPresets.value?.cylinderType
            : currentData.value.cylinderType,
        dirtExposure:
          currentData.value.dirtExposure == null
            ? currentPresets.value?.dirtExposure
            : currentData.value.dirtExposure,
        operatingDuration:
          currentData.value.operatingDuration == null
            ? currentPresets.value?.operatingDuration
            : currentData.value.operatingDuration,
        isReplacementPart: basicInput.value.sealsNeeds !== 'newCylinderDesign',
        onlyUsStandardSizes: usStandardSizes.value,
        wiper:
        currentData.value['wiper']?.rodDiameter !== null
          ? currentData.value['wiper']
          : defaultWiper,
        rodSeal:
        currentData.value['rodSeal']?.rodDiameter !== null
          ? currentData.value['rodSeal']
          : defaultRodSeal,
        rodGuide:
        currentData.value['rodGuide']?.rodDiameter !== null
          ? currentData.value['rodGuide']
          : defaultRodGuide,
        pistonSeal:
        currentData.value['pistonSeal']?.boreDiameter !== null
          ? currentData.value['pistonSeal']
          : defaultPistonSeal,
        pistonGuide:
        currentData.value['pistonGuide']?.boreDiameter !== null
          ? currentData.value['pistonGuide']
          : defaultPistonGuide,
        bufferSeal:
        currentData.value['bufferSeal']?.rodDiameter !== null
          ? currentData.value['bufferSeal']
          : defaultBufferSeal
      }
    })

    // Setters
    const setCurrentDataMultiple = (
      properties: { key: keyof RequestBody; value: any }[]
    ) => {
      properties.forEach(({key, value}) => {
        // For nested properties (e.g., unitsOfMeasure.pressure),
        // split the key and update the nested property
        const keys = key.split('.')
        const k = keys[0] as keyof RequestBody

        if (keys.length === 1) {
          currentData.value[k] = value
        } else if (
          keys.length === 2 &&
          typeof currentData.value[k] === 'object' &&
          currentData.value[k] !== null
        ) {
          setNestedProperty(
            currentData.value[k] as keyof (typeof currentData.value)[typeof k],
            keys.slice(1),
            value
          )
        }
      })
    }

    const setNestedProperty = (
      obj: Record<string, any>,
      keys: string[],
      value: any
    ) => {
      let currentObj = obj

      for (let i = 0; i < keys.length - 1; i++) {
        const key = keys[i]
        if (typeof currentObj[key] !== 'object' || currentObj[key] === null) {
          currentObj[key] = {}
        }
        currentObj = currentObj[key]
      }

      const lastKey = keys[keys.length - 1]
      currentObj[lastKey] = value
    }

    // Setters
    const setSelectorState = (state: HydraulicSelectorState) => {
      if (Object.values(HydraulicSelectorState).includes(state)) {
        selectorState.value = state
      }
    }

    const setCurrentData = (
      data: components['schemas']['ApiPostProductRequest']
    ) => {
      // TODO add typechecker
      currentData.value = data
    }

    const setPresets = (
      data: components['schemas']['ApiApplicationPreset'][]
    ) => {
      // TODO add typechecker
      presets.value = data
    }

    const setPresetsImperial = (data: components['schemas']['ApiApplicationPreset'][]) => {
      presetsImperial.value = data
    }

    const setMetadata = (
      data: components['schemas']['ApiHydraulicMetaData']
    ) => {
      // TODO add typechecker
      metadata.value = data
    }

    const setMetadataImperial = (
      data: components['schemas']['ApiHydraulicMetaData']
    ) => {
      metadataImperial.value = data
    }

    const setUnitLength = (value: string) => {
      unitLength.value = value
    }

    const setUnitPressure = (value: string) => {
      unitPressure.value = value
    }

    const setUnitSpeed = (value: string) => {
      unitSpeed.value = value
    }

    const setUnitTemperature = (value: string) => {
      unitTemperature.value = value
    }

    const setUsStandardSizes = (value: boolean) => {
      usStandardSizes.value = value
    }

    const loadDefaultData = (forceUpdate: boolean = false) => {
      if (presetsAndMetadataInitialized.value === false || forceUpdate) {
        if (Object.keys(currentData.value).length === 0) {
          setCurrentData(newEmptyPostBody())
        }
        const currentRegion = useNuxtApp().$globalization.getRegion()
        const promises = currentRegion !== REGION_KEYS.NA
          ? [useHydraulicSelectorService().getPresets(), useHydraulicSelectorService().getMetadata()]
          : [useHydraulicSelectorNAService().getPresets(), useHydraulicSelectorNAService().getMetadata()]

        Promise.all(promises)
          .then((result: any) => {
            setPresets(result[0].filter((preset: any) => preset.isNaDefault === false))
            setPresetsImperial(result[0].filter((preset: any) => preset.isNaDefault === true))
            setMetadata(result[1].find((metadata: any) => metadata.isNaDefault === false))
            setMetadataImperial(result[1].find((metadata: any) => metadata.isNaDefault === true))
          })
      }
    }
    const resetState = () => {
      selectorState.value = HydraulicSelectorState.startpage
      currentData.value = {}
      presets.value = []
      presetsImperial.value = []
      metadata.value = {}
      metadataImperial.value = {}
      basicInput.value = {sealsNeeds: 'newCylinderDesign'}
      checkboxes.value = {}
      replacementStep.value = 0
      wiperProfile.value = ''
      rodSealProfile.value = ''
      rodGuideProfile.value = '0'
      bufferSealProfile.value = ''
      pistonSealProfile.value = ''
      pistonGuideProfile.value = ''
      wiperDefault.value = ''
      pistonSealDefaultB.value = false
      isErrorResponse.value = false
      unitLength.value = 'mm'
      unitTemperature.value = 'celsius'
      unitPressure.value = 'bar'
      unitSpeed.value = 'm/s'
    }

    const initializeUnits = () => {
      if (unitSwitchingEnabled.value) {
        setUnitLength('inch')
        setUnitTemperature('fahrenheit')
        setUnitPressure('psi')
        setUnitSpeed('ft/s')
      } else {
        setUnitLength('mm')
        setUnitTemperature('celsius')
        setUnitPressure('bar')
        setUnitSpeed('m/s')
      }
    }

    initializeUnits()

    watch(currentRegion, (newRegion, oldRegion) => {
      if (newRegion !== oldRegion) {
        initializeUnits()
        setCurrentData(newEmptyPostBody())
        loadDefaultData(true)
        basicInput.value = {
          rodDiameter: null,
          outerDiameter: null,
          sealsNeeds: 'newCylinderDesign',
          applicationArea: presets.value.sort((a, b) => a.displayOrder - b.displayOrder)[0]?.applicationPreset
        }
        checkboxes.value = {}
        wiperDefault.value = 'design_ab'
        pistonSealDefaultB.value = false
        isErrorResponse.value = false
      }
    })

    return {
      // state
      basicInput,
      selectorState,
      currentData,
      presets,
      presetsImperial,
      metadata,
      metadataImperial,
      checkboxes,
      replacementStep,
      wiperProfile,
      rodSealProfile,
      rodGuideProfile,
      bufferSealProfile,
      pistonSealProfile,
      pistonGuideProfile,
      wiperDefault,
      pistonSealDefaultB,
      isErrorResponse,
      isInch,
      unitLength,
      unitTemperature,
      unitPressure,
      unitSpeed,
      usStandardSizes,
      unitSwitchingEnabled,
      // getters
      presetsAndMetadataInitialized,
      dataIncompleteForStepTwo,
      getPostRequestObject,
      // setters
      setCurrentDataMultiple,
      setSelectorState,
      setCurrentData,
      setPresets,
      setMetadata,
      loadDefaultData,
      resetState,
      setUnitLength,
      setUnitPressure,
      setUnitSpeed,
      setUnitTemperature,
      setUsStandardSizes,
      initializeUnits
    }
  }, {
    persist: {
      storage: persistedState.sessionStorage,
      paths: [
        'currentData',
        'basicInput',
        'checkboxes',
        'replacementStep',
        'unitLength',
        'unitPressure',
        'unitSpeed',
        'unitTemperature'
      ]
    }
  }
)
