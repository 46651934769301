import {NON_ATTRIBUTE_FILTERS, REGEX_MAPPING, PROPERTY_SCOPE_STATIC, PROPERTY_SCOPE_DYNAMIC, PROPERTY_SCOPE_NAMES, PROPERTY_SCOPE_FIELDS} from '../assets/js/constants'
import {isNoAttributeFilter, VALUE_TYPES} from '../assets/js/filters'

export const getDesignQuery = function (designs) {
  const designsLength = designs ? designs.length - 1 : -1
  if (designsLength < 0) {
    return null
  }

  let whereQuery = []
  designs.forEach((design) => {
    whereQuery.push(`ProductDesign eq '${design.TechnicalName}'`)
  })

  return '`(' + whereQuery.join(' or ') + ')`'
}

export const getDesignQueryNew = function (designs) {
  const designsLength = designs ? designs.length - 1 : -1
  if (designsLength < 0) {
    return null
  }

  let whereQuery = []
  designs.forEach((design) => {
    whereQuery.push(`productDesign eq '${design.TechnicalName}'`)
  })

  return '`(' + whereQuery.join(' or ') + ')`'
}

export const getDesignQueryForCategory = function(technicalName) {
  let query = `Categories/any(c: c eq '${technicalName}')`
  return '`' + query  + '`'
}

export const getDesignQueryForCategoryNew = function(technicalName) {
  let query = `categories/any(c: c eq '${technicalName}')`
  return '`' + query  + '`'
}

export const getSelectByScope = function(selectScope, attributeSelects = [], loggedIn = false) {
  if (!selectScope) {return null}
  if (attributeSelects.length > 0) {
    attributeSelects = attributeSelects.filter(s => !REGEX_MAPPING.Characteristics.test(s) && !REGEX_MAPPING.Attributes.test(s))?.map(f => f.replace(REGEX_MAPPING.ToLowerCase, (match, p1, p2) => p1.toLowerCase() + p2))
  }
  let selectProps = null
  switch (selectScope) {
  case PROPERTY_SCOPE_NAMES.PRODUCT_PAGE:
    selectProps = [... new Set(PROPERTY_SCOPE_STATIC.PRODUCT_DETAIL.concat(PROPERTY_SCOPE_DYNAMIC.PRODUCT_DETAIL).concat(attributeSelects))]
    break
  case PROPERTY_SCOPE_NAMES.PRODUCT_PAGE_NEW:
    selectProps = [... new Set(PROPERTY_SCOPE_STATIC.PRODUCT_DETAIL_NEW.concat(PROPERTY_SCOPE_DYNAMIC.PRODUCT_DETAIL).concat(attributeSelects))]
    break
  case PROPERTY_SCOPE_NAMES.CART:
    selectProps = [... new Set(PROPERTY_SCOPE_STATIC.CART.concat(PROPERTY_SCOPE_DYNAMIC.CART).concat(attributeSelects))]
    break
  case PROPERTY_SCOPE_NAMES.SEARCH_RESULT_PAGE:
    selectProps = [... new Set(PROPERTY_SCOPE_STATIC.SEARCH_RESULTS.concat(PROPERTY_SCOPE_DYNAMIC.SEARCH_RESULTS).concat(attributeSelects))]
    break
  case PROPERTY_SCOPE_NAMES.SEARCH_RESULT_PAGE_NEW:
    selectProps = [... new Set(PROPERTY_SCOPE_STATIC.SEARCH_RESULTS_NEW.concat(PROPERTY_SCOPE_DYNAMIC.SEARCH_RESULTS).concat(attributeSelects))]
    break
  case PROPERTY_SCOPE_NAMES.SEARCH_OVERLAY:
    selectProps = [... new Set(PROPERTY_SCOPE_STATIC.SEARCH_OVERLAY.concat(PROPERTY_SCOPE_DYNAMIC.SEARCH_OVERLAY).concat(attributeSelects))]
    break
  case PROPERTY_SCOPE_NAMES.SEARCH_OVERLAY_NEW:
    selectProps = [... new Set(PROPERTY_SCOPE_STATIC.SEARCH_OVERLAY_NEW.concat(PROPERTY_SCOPE_DYNAMIC.SEARCH_OVERLAY).concat(attributeSelects))]
    break
  case PROPERTY_SCOPE_NAMES.CATEGORY_PAGE:
    selectProps = [... new Set(PROPERTY_SCOPE_STATIC.CATEGORY_PAGE.concat(PROPERTY_SCOPE_DYNAMIC.CATEGORY_PAGE).concat(attributeSelects))]
    break
  case PROPERTY_SCOPE_NAMES.CATEGORY_PAGE_NEW:
    selectProps = [... new Set(PROPERTY_SCOPE_STATIC.CATEGORY_PAGE_NEW.concat(PROPERTY_SCOPE_DYNAMIC.CATEGORY_PAGE).concat(attributeSelects))]
    break
  case PROPERTY_SCOPE_NAMES.SUCCESSOR_POPUP:
    selectProps = PROPERTY_SCOPE_STATIC.SUCCESSOR_POPUP
    break
  case PROPERTY_SCOPE_NAMES.PLANTS_ONLY:
    selectProps = PROPERTY_SCOPE_STATIC.PLANTS_ONLY
    break
  case PROPERTY_SCOPE_NAMES.PLANTS_ONLY_NEW:
    selectProps = PROPERTY_SCOPE_STATIC.PLANTS_ONLY_NEW
    break
  case PROPERTY_SCOPE_NAMES.DESIGN_ONLY:
    selectProps = [PROPERTY_SCOPE_FIELDS.PRODUCT_DESIGN]
    break
  case PROPERTY_SCOPE_NAMES.VARIANT_ITEMS:
    selectProps = PROPERTY_SCOPE_STATIC.VARIANT_ITEMS
    break
  case PROPERTY_SCOPE_NAMES.VARIANT_ITEMS_NEW:
    selectProps = PROPERTY_SCOPE_STATIC.VARIANT_ITEMS_NEW
    break
  case 'sitemap':
    selectProps = ['sku', 'lastUpdate']
    break
  case PROPERTY_SCOPE_NAMES.HYDRAULIC_SELECTOR:
    selectProps = PROPERTY_SCOPE_STATIC.HYDRAULIC_SELECTOR
    break
  case PROPERTY_SCOPE_NAMES.HYDRAULIC_SELECTOR_NEW:
    selectProps = PROPERTY_SCOPE_STATIC.HYDRAULIC_SELECTOR_NEW
    break
  case PROPERTY_SCOPE_NAMES.ORDER_HISTORY_NEW:
    selectProps = PROPERTY_SCOPE_STATIC.ORDER_HISTORY_NEW
    break
  default:
    return null
  }

  if (selectProps && (!loggedIn)) {
    const listOfRemovals = [
      PROPERTY_SCOPE_FIELDS.PLANTS,
      PROPERTY_SCOPE_FIELDS.CUSTOMER_MAPPINGS,
      PROPERTY_SCOPE_FIELDS.DEFAULT_PLANT_CODE,
      PROPERTY_SCOPE_FIELDS.ALTERNATIVE_PLANT_CODE
    ]
    for (let removal of listOfRemovals) {
      const index = selectProps.indexOf(removal)
      if (index > -1) {
        selectProps.splice(index, 1)
      }
    }
  }

  return selectProps
}

export const getProductQueryParams = function(query, facets, filters, options, searchFields = null, newSearch = false) {
  addPaginationAndOrderToQuery(query, options)
  let filtersWhereQuery = getProductFilterQuery(filters, newSearch)
  if (filtersWhereQuery) {
    query = query.where(filtersWhereQuery)
  }
  let additionalParams = facets && Object.keys(facets).length > 0 ? {facet: facets} : null
  // remove duplicates
  if (additionalParams && additionalParams.facet && Array.isArray(additionalParams.facet)) {
    additionalParams.facet = additionalParams.facet.filter((v, i) => additionalParams.facet.indexOf(v) === i)
  }

  if (searchFields) {
    additionalParams = {...additionalParams, ...{['searchFields']: searchFields}}
  }
  return query.toPostParams(additionalParams)
}

export const addPaginationAndOrderToQuery = function(query, options) {
  if (options) {
    query.top(options.pagination.take).skip(options.pagination.skip)
    if (options.sort.length > 0) {
      options.sort.forEach((sortOption, index) => {
        const sortField = sortOption.field
        let orderByField
        if (sortField === 'materials/0/key') {
          orderByField = NON_ATTRIBUTE_FILTERS.MATERIALIDENTIFIER
        } else if (sortField === 'materials/0/compound') {
          orderByField = NON_ATTRIBUTE_FILTERS.POLYMER
        } else if (sortField === 'materials/0/shoreHardness') {
          orderByField = NON_ATTRIBUTE_FILTERS.SHORE_HARDNESS
        } else if (REGEX_MAPPING.Characteristics.test(sortField)) {
          const match = sortField.match(REGEX_MAPPING.Characteristics)
          if (match.length === 3) {
            orderByField = 'CharacteristicsValue/' + match[2]
          }
        } else if (REGEX_MAPPING.AttributesNew.test(sortField)) {
          const attributeSplit = sortField.split('/')
          if (attributeSplit && attributeSplit.length > 1) {
            orderByField = 'characteristicsValues/' + attributeSplit[1]
          }
        } else if (REGEX_MAPPING.MaterialWithIndex.test(sortField)) {
          const match = sortField.match(REGEX_MAPPING.MaterialWithIndex)
          if (match.length === 2) {
            orderByField = 'materials/' + match[1] + '/key'
          }
        }
        if (!orderByField) {
          orderByField = sortField
        }

        if (index === 0) {
          sortOption.dir === 'asc' ? query.orderBy(orderByField) : query.orderByDesc(orderByField)
        } else {
          sortOption.dir === 'asc' ? query.thenBy(orderByField) : query.thenByDesc(orderByField)
        }
      })
    }
  }
}

export const getProductFilterQuery = function (filters, newSearch = false) {
  if (!filters || filters.length <= 0) {
    return null
  }

  const activeFilters = filters.filter(f => f.isActive)
  let whereQuery = []

  activeFilters.forEach((filter) => {
    if (isNoAttributeFilter(filter.shortName, newSearch)) {
      const filterQuery = getQueryFilter(filter, newSearch)
      if (!filterQuery) {
        return
      }

      if (filter.isCollectionFilter) {
        if (newSearch) {
          whereQuery.push('`(' + filter.collectionName.toLowerCase() + '/any(c: ' + filterQuery + '))`')
        } else {
          whereQuery.push('`(' + filter.collectionName + '/any(c: ' + filterQuery + '))`')
        }
      }
      else {
        whereQuery.push('`(' + filterQuery + ')`')
      }
    }
    else {
      const filterQuery = getAttributeQueryFilter(filter, newSearch)
      if (!filterQuery) {
        return
      }
      if (newSearch) {
        let filterName = filter.indexPath ? filter.indexPath.split('/')[1] : filter.shortName
        whereQuery.push('`(characteristics/any(c: c/name eq \'' + filterName + '\' and ' + filterQuery + '))`')
      } else {
        whereQuery.push('`(Characteristics/any(c: c/Key eq \'' + filter.shortName + '\' and ' + filterQuery + '))`')
      }
    }
  })

  return whereQuery.join(' and ')
}

const OAUTH_SEARCH_IN_SEPARATOR = '||'

// Query filter for non attribute fields
export const getQueryFilter = function (filter, newSearch = false) {
  if (filter.customQuery) {
    return filter.customQuery
  }
  let collectionKey = newSearch ? 'name' : filter.collectionKey
  let searchField = filter.isCollectionFilter ? 'c/' + collectionKey : filter.shortName
  if (filter.type === VALUE_TYPES.STRING) {
    return 'search.in(' + searchField + ', \'' + filter.values?.map(v => v.value).join(OAUTH_SEARCH_IN_SEPARATOR) + '\', \'' + OAUTH_SEARCH_IN_SEPARATOR + '\')'
  }
  else if (filter.type === VALUE_TYPES.DROPDOWN || filter.type === VALUE_TYPES.TEXT) {
    return 'search.in(' + searchField + ', \'' + filter.values.value + '\', \'' + OAUTH_SEARCH_IN_SEPARATOR + '\')'
  }
  else if (filter.type === VALUE_TYPES.INTEGER || filter.type === VALUE_TYPES.FLOAT) {
    let queries = []
    const toleranceDelta = filter.values.tolerance === 0 ? 0.013 : 0
    const from = filter.values.from ? filter.values.from * (100 - filter.values.tolerance) / 100 - toleranceDelta : null
    const to = filter.values.from ? filter.values.from * (100 + filter.values.tolerance) / 100 + toleranceDelta : null
    if (from) {
      queries.push(searchField + ' ge ' + from.toFixed(10))
    }
    if (to) {
      queries.push(searchField + ' le ' + to.toFixed(10))
    }
    return queries.join(' and ')
  }
  else if (filter.type === VALUE_TYPES.DATE) {
    let queries = []
    if (filter.values.start) {
      queries.push(searchField + ' ge ' + filter.values.start)
    }
    if (filter.values.end) {
      queries.push(searchField + ' le ' + filter.values.end)
    }
    return queries.join(' and ')
  }

  return ''
}

// Query filter for attribute fields
export const getAttributeQueryFilter = function (filter, newSearch = false) {
  const identifier = newSearch ? 'valueString' : 'Value'
  const doubleIdentifier = newSearch ? 'valueDouble' : 'ValueDec'
  if (filter.type === VALUE_TYPES.STRING) {
    return 'search.in(c/' + identifier + ', \'' + filter.values?.map(v => v.value).join(OAUTH_SEARCH_IN_SEPARATOR) + '\', \'' + OAUTH_SEARCH_IN_SEPARATOR + '\')'
  }
  else if (filter.type === VALUE_TYPES.MULTI_STRING) {
    return 'c/valueArrString/any(t: t eq \'' + filter.values?.map(v => v.value).join('\' or t eq \'') + '\')'
  }
  else if (filter.type === VALUE_TYPES.DROPDOWN || filter.type === VALUE_TYPES.TEXT) {
    return 'search.in(c/' + identifier + ', \'' + + filter.values.value + '\', \'' + OAUTH_SEARCH_IN_SEPARATOR + '\')'
  }
  else if (filter.type === VALUE_TYPES.INTEGER || filter.type === VALUE_TYPES.FLOAT) {
    let queries = []
    const toleranceDelta = filter.values.tolerance === 0 ? 0.013 : 0
    const from = filter.values.from ? filter.values.from * (100 - filter.values.tolerance) / 100 - toleranceDelta : null
    const to = filter.values.from ? filter.values.from * (100 + filter.values.tolerance) / 100 + toleranceDelta : null
    if (from) {
      queries.push('c/' + doubleIdentifier + ' ge ' + from.toFixed(10))
    }
    if (to) {
      queries.push('c/' + doubleIdentifier + ' le ' + to.toFixed(10))
    }
    return queries.join(' and ')
  }
  else if (filter.type === VALUE_TYPES.MULTI_DOUBLE) {
    const toleranceDelta = filter.values.tolerance === 0 ? 0.013 : 0
    const from = filter.values.from ? filter.values.from * (100 - filter.values.tolerance) / 100 - toleranceDelta : null
    const to = filter.values.from ? filter.values.from * (100 + filter.values.tolerance) / 100 + toleranceDelta : null
    if (from && to) {
      return 'c/valueArrDouble/any(t: t ge ' + from.toFixed(10) + ' and t le ' + to.toFixed(10) + ')'
    }
  }
  else if (filter.type === VALUE_TYPES.DATE) {
    let queries = []
    if (filter.values.start) {
      queries.push('c/' + doubleIdentifier + ' ge ' + filter.values.start)
    }
    if (filter.values.end) {
      queries.push('c/' + doubleIdentifier + ' le ' + filter.values.end)
    }
    return queries.join(' and ')
  }

  return ''
}

export const buildFilterUrl = function(url, filters) {
  filters = filters.join(' and ')
  for (const filter of filters) {
    url += filter
  }
  url += ')'
  return url
}
