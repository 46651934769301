<template>
  <div class="product-details-wrapper">
    <div class="product-details-component">
      <div class="product-details-component__header">
        <h5
          v-tv:product="'details'"
        />
      </div>
      <div class="product-details-main">
        <div class="product-details-main__images-container">
          <product-gallery
            :product="product"
            :product-name="productDescription"
            class="newpdp"
          />
        </div>
        <div class="product-details-main__table">
          <div class="product-details-main__table__row">
            <div
              class="product-details-main__table__row__label"
              v-tv:product="'fst-item-no'"
            />
            <div
              class="product-details-main__table__row__value"
            >
              {{ product?.sku }}
            </div>
          </div>
          <div
            v-if="product?.customerMappings?.number"
            class="product-details-main__table__row"
          >
            <div
              class="product-details-main__table__row__label"
              v-tv:product="'your-item-no'"
            />
            <div
              class="product-details-main__table__row__value"
            >
              {{ product?.customerMappings?.number }}
            </div>
          </div>
          <div
            v-if="visibleSkus.length > 0"
            class="product-details-main__table__row"
          >
            <div
              class="product-details-main__table__row__label"
              v-tv:product="'legacy-item-no'"
            />
            <div class="product-details-legacy-sku-values">
              <ul>
                <li
                  class="product-details-main__table__row__value"
                  v-for="(sku, index) in visibleSkus"
                  :key="index"
                >
                  {{ sku }}
                </li>
              </ul>
              <div
                v-if="showMoreSkusButton"
                class="show-more-skus"
                @click="handleShowMoreSkusClick"
              >
                <span>
                  {{ showMoreSkusButtonText }}
                </span>
                <NavigationExpandMore
                  :class="{
                    'expand-more-icon': !this.showAllSkus,
                    'expand-less-icon': this.showAllSkus
                  }"
                />
              </div>
            </div>
          </div>
          <div
            v-if="visibleNorms.length > 0"
            class="product-details-main__table__row"
          >
            <div
              class="product-details-main__table__row__label"
              v-tv:product="'norms'"
            />
            <div
              class="product-details-main__table__row__value"
            >
              <div>
                <ul
                  v-for="(item, index) in visibleNorms"
                  :key="index"
                >
                  <li>
                    {{ item }}
                  </li>
                  <div v-if="displayInfo" />
                </ul>
                <div
                  class="toggle-norms"
                  @click="toggleInformation"
                >
                  <span class="show-more-or-less-norms">
                    {{ showMoreOrLess }}
                  </span>
                  <ExpandMoreLess
                    :class="{
                      'expand-more-icon': this.displayInfo === false,
                      'expand-less-icon': this.displayInfo === true
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="$auth.loggedIn"
            class="product-details-main__table__row"
          >
            <div
              class="product-details-main__table__row__label"
              v-tv:product="'status'"
            />
            <div
              class="product-details-main__table__row__value"
              :class="{'status-red': getStatus === 'Discontinued' || getStatus === 'LimitedAvailable'}"
            >
              <div
                class="product-availability-warning"
                :class="{'no-tooltip': getStatus === 'Discontinued'}"
                @mouseenter="statusVisibility(true)"
                @mouseleave="statusVisibility(false)"
              >
                {{ $tv(getStatus, 'status') }}
                <AlertWarning
                  v-if="getStatus === 'LimitedAvailable'"
                />
              </div>
            </div>
            <ToolTip
              :show-tool-tip="showLimitedAvailabilityToolTip"
              tool-tip-position="bottom"
              arrow-position-vertically="top"
              arrow-position-horizontally="middle"
            >
              <div class="tooltip-message pdp-legal-tooltip">
                <span v-tv:status="'Important Legal Note Header'" />
                <p
                  class="tooltip-availability-text"
                >
                  <span v-tv:status="'Important Legal Note Text P1'" />
                  <span v-tv:status="'Important Legal Note Text P2'" />
                  <span v-tv:status="'Important Legal Note Text P3'" />
                  <span v-tv:status="'Important Legal Note Text P4'" />
                </p>
              </div>
            </ToolTip>
          </div>
          <div
            class="product-details-main__table__row"
            v-if="$auth.loggedIn"
          >
            <div
              class="product-details-main__table__row__label"
              v-tv:product="'repTime'"
            />
            <div
              class="product-details-main__table__row__value"
            >
              {{ replenishmentTimeString }}
            </div>
          </div>
          <div class="product-details-main__table__row">
            <div
              class="product-details-main__table__row__label"
              v-tv:product="'successor'"
            />
            <div class="product-details-main__table__row__value">
              <div
                v-if="successorSku !== '-'"
                class="successor-value-cell"
              >
                <a
                  href="javascript: void(0);"
                  @click.prevent="$emit('open-variants')"
                >
                  {{ successorSku }}
                </a>
              </div>
              <div v-else>
                <span>
                  {{ successorSku }}
                </span>
              </div>
            </div>
          </div>
          <div class="product-details-main__table__row">
            <div
              class="product-details-main__table__row__label"
              v-tv:product="'variant'"
            />
            <div
              class="product-details-main__table__row__value"
            >
              <div v-if="hasVariants && $auth.loggedIn">
                <a
                  href="javascript: void(0);"
                  @click.prevent="$emit('open-variants')"
                >
                  {{ variant }}
                </a>
              </div>
              <div v-else>
                <span>
                  {{ variant }}
                </span>
              </div>
            </div>
          </div>
          <div class="product-details-main__table__row">
            <div
              class="product-details-main__table__row__label"
              v-tv:product="'net-weight'"
            />
            <div
              class="product-details-main__table__row__value"
            >
              <QuantityDisplay
                :quantity="product?.netWeight"
                :unit="product?.weightUnitOfMeasure"
              />
            </div>
          </div>
          <div class="product-details-main__table__row">
            <div
              class="product-details-main__table__row__label"
              v-tv:product="'packaging'"
            />
            <div
              class="product-details-main__table__row__value"
            >
              <QuantityDisplay
                :quantity="product?.variant?.amount"
                :unit="product?.variant?.unit ?? ''"
              />
            </div>
          </div>
          <div
            v-if="$auth.loggedIn"
            class="product-details-main__table__row"
          >
            <div
              class="product-details-main__table__row__label"
              v-tv:product="'country-of-origin'"
            />
            <div
              class="product-details-main__table__row__value"
              v-if="product?.plants?.[0]?.countryOfOrigin != null"
              v-text="$tv(product?.plants?.[0]?.countryOfOrigin, 'countries')"
            />
            <div
              class="product-details-main__table__row__value"
              v-else
            >
              <span>{{ $tv('multiSourcedMaterial', 'product') }}&nbsp;
                <ActionInfoFilledIcon
                  class="clickable"
                  @click="showMultiSourceMaterialInfo = !showMultiSourceMaterialInfo"
                />
              </span>
            </div>
            <ToolTip
              v-if="showMultiSourceMaterialInfo"
              :show-tool-tip="showMultiSourceMaterialInfo"
              tool-tip-position="bottom"
              arrow-position-vertically="top"
              arrow-position-horizontally="middle"
              :allow-close="true"
              @close="showMultiSourceMaterialInfo = false"
            >
              {{ $tv('multiSourcedMaterialInfo', 'product') }}
            </ToolTip>
          </div>
          <div
            v-if="$auth.loggedIn"
            class="product-details-main__table__row"
          >
            <div
              class="product-details-main__table__row__label"
              v-tv:cart="'hsCode'"
            />
            <div
              class="product-details-main__table__row__value"
            >
              {{ getHsCode }}
            </div>
          </div>
          <div
            v-if="getReach"
            class="product-details-main__table__row"
          >
            <div
              class="product-details-main__table__row__label"
              v-tv:cart="'reach'"
            />
            <div
              class="product-details-main__table__row__value"
            >
              {{ getReach }}
            </div>
          </div>
          <div class="product-details-main__table__row">
            <div
              class="product-details-main__table__row__label"
              v-tv:product="'brand'"
            />
            <div
              class="product-details-main__table__row__value"
            >
              <div class="brandname-cell">
                <span>
                  {{ $tv(product?.brandName, 'brands') }}
                </span>
              </div>
            </div>
          </div>
          <div
            v-for="(material, index) in materials"
            :key="index"
            class="product-details-main__table__row"
          >
            <div
              class="product-details-main__table__row__label"
              v-tv="materialCount(index)"
            />
            <div
              class="product-details-main__table__row__value"
            >
              {{ material.key }}
            </div>
          </div>
          <div
            v-if="downloadFiles.length > 0"
            class="product-details-main__table__row"
          >
            <div
              class="product-details-main__table__row__label downloads-cell"
              v-tv:product="'downloads'"
            />
            <div
              class="product-details-main__table__row__value"
            >
              <div class="downloads-value-cell">
                <ul>
                  <li
                    v-for="(file, index) in sortedAndFilteredFiles"
                    :key="index"
                  >
                    <a
                      :href="getDownloadLink(file, url)"
                      target="_blank"
                      download
                      class="downloads"
                      :title="getAltText(file)"
                    >
                      {{ getFileName(file, $tv) }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import itemImageUrlMixin from '~/mixins/item_image_url'
import ExpandMoreLess from '~/assets/icons/expand_more_arrow.svg?component'
import {PRODUCT_ATTRIBUTES, FALLBACK_IMAGE} from '~/assets/js/constants'
import availabilitiesMixin from '~/mixins/availabilities'
import QuantityDisplay from '@/components/QuantityDisplay.vue'
import ProductGallery from '@/components/product-detail/ProductGallery.vue'
import {useDownloadFunctions} from '@/composables/download_functions.js'
import AlertWarning from '~/assets/icons/alert_warning.svg?component'
import ToolTip from '~/components/ToolTip.vue'
import ActionInfoFilledIcon from '~/assets/icons/action_info_filled.svg?component'
import NavigationExpandMore from '~/assets/icons/navigation_expand_more.svg?component'

export default {
  mixins: [availabilitiesMixin, itemImageUrlMixin],
  data() {
    return {
      displayInfo: false,
      showLimitedAvailabilityToolTip: false,
      replenishmentTime: null,
      showMultiSourceMaterialInfo: false,
      showAllSkus: false
    }
  },
  components: {
    ExpandMoreLess,
    QuantityDisplay,
    ProductGallery,
    AlertWarning,
    ToolTip,
    ActionInfoFilledIcon,
    NavigationExpandMore
  },
  props: {
    product: {
      type: Object,
      required: false,
      default() {
        return null
      }
    },
    brand: {
      type: Object,
      required: false,
      default() {
        return null
      }
    },
    downloadFiles: {
      type: Array,
      required: false,
      default() {
        return []
      }
    },
    productName: {
      type: String,
      default () {
        return ''
      }
    }
  },
  setup() {
    const {getAltText, getDownloadLink, getFileName} = useDownloadFunctions()
    return {
      getAltText,
      getDownloadLink,
      getFileName
    }
  },
  computed: {
    getStatus() {
      return this.product?.salesAreas?.[0]?.statusLabel ?? '-'
    },
    successorSku() {
      let successorSku = this.product?.salesAreas?.[0]?.successorSku ?? this.product?.successorSku ?? '-'
      return this.$auth.loggedIn ? successorSku : '-'
    },
    productDescription: function() {
      return this.product?.locales[this.$i18n.locale] ?? ''
    },
    visibleSkus() {
      if (!this.product?.legacysku) {
        return []
      }

      if (Array.isArray(this.product.legacysku)) {
        return this.showAllSkus ? this.product.legacysku : this.product.legacysku.slice(0, 2)
      }

      return [this.product.legacysku]
    },
    showMoreSkusButton() {
      return Array.isArray(this.product?.legacysku) ? this.product?.legacysku.length > 2 : false
    },
    showMoreSkusButtonText() {
      return this.showAllSkus ? this.$tv('show-less', 'product') : this.$tv('show-more', 'product')
    },
    visibleNorms() {
      return []
      //will be filled in later feature with data
      /* if (this.displayInfo === true) {
        return this.product?.norms ?? []
      }
      return (this.product?.norms ?? []).slice(0, 2) */
    },
    showMoreOrLess() {
      return this.displayInfo ? this.$tv('show-less', 'product') : this.$tv('show-more', 'product')
    },
    variant: function() {
      const variantResults = this.getVariantText(this.product?.variant)
      return variantResults !== '' ? variantResults : '-'
    },
    hasVariants: function() {
      return this.product?.otherVariants?.length > 0
    },
    getHsCode() {
      return this.product?.plants?.[0].commodityCode ?? '-'
    },
    getReach() {
      const reach = this.product?.characteristics?.find(a => a.key === PRODUCT_ATTRIBUTES.Reach || a.name === PRODUCT_ATTRIBUTES.Reach)
      const reachValue = reach?.value ?? reach?.valueString
      if (reachValue) {
        return this.$tv(reachValue, 'reach')
      }
      return null
    },
    materials: function() {
      return this.product?.materials
    },
    imageList() {
      let result = []
      const imageUrl = this.imageUrl(this.product, true)
      if (imageUrl !== FALLBACK_IMAGE) {
        result.push({alternativeText: this.productName, url: imageUrl})
      }
      if (this.product && this.product.galleryImages) {
        result = result.concat(this.product.galleryImages.map(i => ({alternativeText: i.AlternativeText, url: i.Image.url})))
      }
      return result
    },
    sortedAndFilteredFiles() {
      const tempFiles = [...this.downloadFiles]
      const sortedFiles = tempFiles.sort((a, b) => a.order - b.order)
      const filteredFiles = sortedFiles?.filter((file, currentIndex, array) => {
        const firstIndex = array.findIndex(f => f.lookupKey === file.lookupKey)
        return currentIndex === firstIndex || !(file.sourceCMS && !array[firstIndex].sourceCMS)
      })
      return filteredFiles
    },
    replenishmentTimeString() {
      let days = this.product?.plants?.[0]?.replenishmentTime
      if (days >= 365) {
        return this.$tv('repTimeYear', 'product')
      } else if (days >= 7) {
        const weeks = Math.ceil(days / 7)
        return this.$t('product.days', {days}, days) + ' / ' + this.$t('product.replenishmentTimeWeeks', {weeks, replenishmentTime: weeks})
      } else if (days === 0 || days === null) {
        return '-'
      } else {
        return this.$t('product.days', {days}, days)
      }
    }
  },
  methods: {
    toggleInformation() {
      this.displayInfo = !this.displayInfo
    },
    materialCount(index) {
      if (this.materials.length === 1) {
        return this.$tv('material', 'product')
      }
      return this.$tv('material', 'product') + ' ' + (index + 1)
    },
    statusVisibility(visibility) {
      if (visibility && this.getStatus === 'LimitedAvailable') {
        this.showLimitedAvailabilityToolTip = true
      } else {
        this.showLimitedAvailabilityToolTip = false
      }
    },
    handleShowMoreSkusClick() {
      this.showAllSkus = !this.showAllSkus
    }
  }
}

</script>

<style lang="scss" scoped>

.product-details-component {
  width: 100%;

  h5 {
    margin: 0 0 .5rem;
  }

  .brandlogo-container {
    height: 1rem;
    display: flex;
    align-items: center;

    img {
      height: 1.5rem;
    }
  }

  .product-details-main {
    display: flex;
    gap: 1rem;
    width: 100%;

    &__images-container {
      width: 33%;

      &__main-img-container {
        img {
          width: 100%;
        }
      }

      &__smaller-images-container {
        display: flex;
        flex-wrap: wrap;

        .small-img-container {
          width: 5.5rem;

          img {
            width: 100%;
          }
        }
      }

      @include breakpoint-down($sm) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
    }

    @include breakpoint-down($sm) {
      flex-flow: column nowrap;
      justify-content: normal;
    }

    &__table {
      display: flex;
      flex-direction: column;
      width: 67%;
      min-width: 20rem;
      gap: .625rem;

      &__row {
        display: flex;
        gap: 1rem;
        position: relative;

        .product-details-legacy-sku-values {
          ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
          }

          .show-more-skus {
            display: flex;
            align-items: center;
            cursor: pointer;
            font-weight: $base-text-font-weight-light;
            color: $color__fst__primary__hover;
            margin-bottom: -.3rem;

            @include breakpoint-down($sm) {
              justify-content: flex-end;
            }

            svg {
              width: 1.5rem;
            }

            .expand-more-icon {
              transform: rotate(0deg);
            }

            .expand-less-icon {
              transform: rotate(180deg);
            }
          }

          @include breakpoint-down($sm) {
            text-align: right;
          }
        }

        @include breakpoint-down($sm) {
          justify-content: space-between;
        }

        &__label {
          min-width: 10rem;
          font-weight:map-get($base-text-label-medium, font-weight);
          font-size: map-get($base-text-label-medium, font-size);
        }

        &__value {
          font-size: map-get($base-text-value-medium, font-size );
          font-weight: map-get($base-text-value-medium, font-weight);

          .downloads-value-cell {
            color: $color__secondary;

            ul {
              margin: 0;
              padding-left: 1.05rem;
            }
          }

          .brandname-cell {
            display: flex;
            gap: 1rem;
            align-items: center;

            div {
              margin: 0;
              margin-left: .5rem;
            }
          }

          .product-availability-warning {
            display: flex;
            align-items: center;
            gap: .25rem;
            cursor: pointer;

            &.no-tooltip {
              cursor: inherit;
            }

            svg {
              font-size: 1.25rem;
            }
          }
        }
      }

      @include breakpoint-down($sm) {
        display: flex;
        flex-flow: column nowrap;
        justify-content: normal;
        width: 100%;
      }
    }

    .status-red {
      color: $color__text--error;
    }
  }
}

.tooltip-message.pdp-legal-tooltip {
  color: $color__text--default;
}
</style>
