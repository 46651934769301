import axios from 'axios'
import {useFetch} from 'nuxt/app'
import {getParams} from './cms_service_helper'
import {setFSTRequestAuthHeader} from '../services/helper/use_fetch_helper'
import {useConfigurationDataStore} from '@/store/configuration-data'

const getAttributesVersion = () => {
  const {newSearch} = useConfigurationDataStore()
  const runtimeConfig = useRuntimeConfig()
  return newSearch && runtimeConfig.public.CMS_ATTRIBUTES_VERSION ? runtimeConfig.public.CMS_ATTRIBUTES_VERSION : undefined
}

function until(conditionFunction) {

  const poll = resolve => {
    if (conditionFunction()) { resolve() }
    /* eslint-disable no-unused-vars */
    else { setTimeout(_ => poll(resolve), 200) }
  }
  return new Promise(poll)
}

export const useCmsService = () => {
  let runtimeConfig
  try {
    runtimeConfig = useRuntimeConfig()
  } catch (_) {
    // useRuntimeConfig not available, must be used out of context
    runtimeConfig = {
      public: process.env
    }
  }
  // strapi-specificum with same paramaters in one query
  const baseurl = runtimeConfig.public.CMS_URL || 'https://cms-stg.dev.fst.com'
  const strapi_axios = axios.create({
    baseURL: baseurl
  })

  let cms_api_config = axios.create({
    baseURL: runtimeConfig.public.CMS_API_URL || 'https://func-enext-cmsapi-stg.azurewebsites.net/api/'
  })

  const getCmsEntityById = async function (entityName, id, lang) {
    const config = {}
    if (entityName === 'translations' || entityName === 'textTranslations') {
      config.baseURL = runtimeConfig.public.TRANSLATION_CMS_URL || baseurl
    }
    else {
      config.baseURL = baseurl
    }

    const res = await useFetch(`/${entityName}/${id}`, {
      ...config,
      method: 'GET',
      params: getParams(lang),
      key: `${entityName}_${id}_${lang}`
    })
    /* eslint-disable no-unused-vars */
    await until(_ => res.pending.value === false)
    return res.data.value
  }

  const getCmsEntitySingle = async function (entityName, lang, filter, populateLocalizations = false) {
    const params = getParams(lang, null, null, null, null, filter)
    const config = {}
    if (entityName === 'translations' || entityName === 'textTranslations') {
      config.baseURL = runtimeConfig.public.TRANSLATION_CMS_URL || baseurl
    }
    else {
      config.baseURL = baseurl
    }
    const res = await useFetch(`/${entityName}`, {
      ...config,
      params: params,
      method: 'GET',
      key: `${entityName}_${lang}_${JSON.stringify(filter)}_${populateLocalizations}`
    })
    /* eslint-disable no-unused-vars */
    await until(_ => res.pending.value === false)
    if (populateLocalizations) {
      await populateLocalizationsForEntities(res.data.value, entityName)
    }
    return res.data.value
  }

  const getBatchedCmsEntityList = async function (entityName, lang, sort, sortDesc, filter, populateLocalizations = false, batchSize = 100) {
    try {
      const totalCount = await countCmsEntities(entityName, lang, filter)
      let currentSkip = 0
      let promiseArray = []
      while (currentSkip < totalCount) {
        promiseArray.push(getCmsEntityList(entityName, lang, currentSkip, batchSize, sort, sortDesc, filter, populateLocalizations))
        currentSkip += batchSize
      }
      return (await Promise.all(promiseArray)).flat()
    } catch {
      return []
    }
  }

  /*
  filter is an array of objects with following format:
  {
    field: the field to filter for,
    operator: one of:
    eq 	        Equal
    ne 	        Not equal
    lt 	        Less than
    gt 	        Greater than
    lte 	      Less than or equal to
    gte 	      Greater than or equal to
    in 	        Included in an array
    nin 	      Not included in an array
    contains 	  Contains
    ncontainss 	Doesn't contain
    containss	  Contains, case sensitive
    ncontainss 	Doesn't contain, case sensitive
    null 	      Is null or not null
    value: the value to compare
  }
  */
  const getCmsEntityList = async function (entityName, lang, skip, top, sort, sortDesc, filter, populateLocalizations = false) {
    if (typeof(skip) !== 'number' && typeof(top) !== 'number') {
      return await getBatchedCmsEntityList(entityName, lang, sort, sortDesc, filter, populateLocalizations)
    } else {
      try {
        const config = {}
        let params = getParams(lang, skip, top, sort, sortDesc, filter)
        if (entityName === 'translations' || entityName === 'textTranslations') {
          config.baseURL = runtimeConfig.public.TRANSLATION_CMS_URL || baseurl
          params = {...params, ...{'_where[_or][0][Entity_ne]': 'apiDocumentation', '_where[_or][1][Entity_null]': 'true'}}
        }
        else {
          config.baseURL = baseurl
        }
        const response = await useFetch(`${entityName}`, {
          method: 'GET',
          ...config,
          params: params,
          key: `${entityName}_${lang}_${skip}_${top}_${sort}_${sortDesc}_${JSON.stringify(filter)}_${populateLocalizations}`
        })
        /* eslint-disable no-unused-vars */
        await until(_ => response.pending.value === false)
        if (populateLocalizations) {
          await populateLocalizationsForEntities(response.data.value, entityName)
        }
        return response.data.value
      } catch {
        return []
      }
    }
  }

  const getConsolidatedCategory = async function (technicalName, region) {
    let config = {url: 'get-consolidated-category', params: {}}
    config.params = {'TechnicalName': technicalName, 'Region': region}
    if (runtimeConfig.public.CMS_API_KEY) {
      config.params.code = runtimeConfig.public.CMS_API_KEY
    }
    const attributesVersion = getAttributesVersion()
    if (attributesVersion) {
      config.params.version = attributesVersion
    }

    config = await setFSTRequestAuthHeader(config, cms_api_config.getUri(config))
    const response = await useFetch('get-consolidated-category', {
      baseURL: cms_api_config.defaults.baseURL,
      ...config,
      method: 'GET',
      key: `categories/designs_null_0_-1_sortIndex_false_[{"field":"TechnicalName","value":"${technicalName}"},{"field":"Region","value":"${region}"}]_false`
    })

    return response.data.value
  }

  const getCategoryAttributes = async function (technicalName, region, showInSearchText) {
    let config = {url: 'get-category-attributes', params: {}}
    config.params = {'TechnicalName': technicalName, 'Region': region}
    if (runtimeConfig.public.CMS_API_KEY) {
      config.params.code = runtimeConfig.public.CMS_API_KEY
    }
    const attributesVersion = getAttributesVersion()
    if (attributesVersion) {
      config.params.version = attributesVersion
    }

    if (showInSearchText) {
      config.params.ShowInSearchText = showInSearchText
    }
    config = await setFSTRequestAuthHeader(config, cms_api_config.getUri(config))
    const response = await useFetch('get-category-attributes', {
      method: 'GET',
      baseURL: cms_api_config.defaults.baseURL,
      ...config,
      key: `category-attributes_${technicalName}_${region}_${showInSearchText}`
    })

    return response.data.value
  }

  const countCmsEntities = async function(entityName, lang, filter) {
    const config = {}
    if (entityName === 'translations' || entityName === 'textTranslations') {
      config.baseURL = runtimeConfig.public.TRANSLATION_CMS_URL || baseurl
    }

    const params = getParams(lang, null, null, null, null, filter)
    return (await strapi_axios.get(`/${entityName}/count`, {...config, params})).data
  }

  const getCategoryContentsForOldUrl = async function (oldUrl, lang) {
    if (oldUrl && oldUrl !== '' && lang && lang !== '') {
      const res = await strapi_axios.get(`/category-contents/old-url/${oldUrl}?_locale=${lang}`)
      return res.data || []
    }
    return []
  }

  async function populateLocalizationsForEntities(res, entityName) {
    if (res.data && res.data.length > 0) {
      let localizationPromises = []
      for (let entry of res.data) {
        if (entry.localizations && entry.localizations.length > 0) {
          localizationPromises = [...localizationPromises, ...entry.localizations.map(l => getCmsEntityById(entityName, l.id, l.locale))]
        }
      }
      let localizedEntries = await Promise.all(localizationPromises)
      if (localizedEntries && localizedEntries.length > 0) {
        for (let entry of res.data) {
          if (entry.localizations && entry.localizations.length > 0) {
            for (let localeEntryIndex = 0; localeEntryIndex < entry.localizations.length; localeEntryIndex++) {
              entry.localizations[localeEntryIndex] = {...entry.localizations[localeEntryIndex], ...localizedEntries.find(l => l.id === entry.localizations[localeEntryIndex].id)}
            }
          }
        }
      }
    }
  }

  return {
    getCategoryContentsForOldUrl,
    countCmsEntities,
    getCmsEntityById,
    getCmsEntitySingle,
    getCmsEntityList,
    getConsolidatedCategory,
    getCategoryAttributes
  }
}
